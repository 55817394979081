import { translate, type Language } from '@orus.eu/translations'

export type ContractDynamicDocumentType =
  | 'agreed-terms'
  | 'payment-schedule'
  | 'general-terms'
  | 'insurance-certificate'
  | 'specific-insurance-certificate-travel-agencies'
  | 'specific-insurance-certificate-real-estate-sales-agents'

type FileWithDynamicName =
  | 'receipts'
  | 'invoice'
  | 'agreed-terms'
  | 'agreed-terms-draft'
  | 'insurance-certificate'
  | 'specific-insurance-certificate-travel-agencies'
  | 'specific-insurance-certificate-real-estate-sales-agents'

type FileWithStaticName =
  | 'agreed-terms'
  | 'agreed-terms-draft'
  | 'general-terms'
  | 'ipid'
  | 'payment-schedule'
  | 'hiscox-broker-information'
  | 'hiscox-data-policy'

export const staticFileNames: { [fileType in FileWithStaticName]: (language: Language) => string } = {
  'agreed-terms': (language) => `Orus - ${translate('filename_agreed_terms', language)}.pdf`,
  'agreed-terms-draft': (language) => `Orus - ${translate('filename_agreed_terms_draft', language)}.pdf`,
  'general-terms': (language) => `Orus - ${translate('filename_general_terms', language)}.pdf`,
  'payment-schedule': (language) => `Orus - ${translate('filename_payments_schedule', language)}.pdf`,

  // not translated because only relevant for french-only products
  ipid: () => `Orus - IPID.pdf`,
  'hiscox-broker-information': () => 'Orus - Fiche Orus.pdf',
  'hiscox-data-policy': () => 'Orus - Politique de confidentialité.pdf',
}

export const dynamicFileNames: {
  [fileType in FileWithDynamicName]: (id: string | undefined, language: Language) => string
} = {
  invoice: (id, language) =>
    id
      ? `Orus - ${translate('filename_invoice', language)} - ${id}.pdf`
      : `Orus - ${translate('filename_invoice', language)}.pdf`,
  receipts: (id, language) =>
    id
      ? `Orus - ${translate('filename_invoice', language)} - ${id}.pdf`
      : `Orus - ${translate('filename_invoice', language)}.pdf`,
  'agreed-terms': (email, language) =>
    email
      ? `Orus - ${translate('filename_agreed_terms', language)} - ${email}.pdf`
      : `Orus - ${translate('filename_agreed_terms', language)}.pdf`,
  'agreed-terms-draft': (email, language) =>
    email
      ? `Orus - ${translate('filename_agreed_terms_draft', language)} - ${email}.pdf`
      : `Orus - ${translate('filename_agreed_terms_draft', language)}.pdf`,
  'insurance-certificate': (email, language) =>
    email
      ? `Orus - ${translate('filename_insurance_certificate', language)} - ${email}.pdf`
      : `Orus - ${translate('filename_insurance_certificate', language)}.pdf`,

  'specific-insurance-certificate-travel-agencies': (email) =>
    email
      ? `Orus - Attestation assurance agences de voyage - ${email}.pdf`
      : `Orus - Attestation assurance agences de voyage.pdf`,

  'specific-insurance-certificate-real-estate-sales-agents': () =>
    `Orus - Attestation assurance agences immobilières.pdf`,
}
